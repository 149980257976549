import { createRouter, createWebHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import type { App } from 'vue'
import { Layout } from '@/utils/routerHelper'
import { useI18n } from '@/hooks/web/useI18n'
import { NO_RESET_WHITE_LIST } from '@/constants'

const { t } = useI18n()

export const constantRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    name: 'Root',
    meta: {
      hidden: true
    }
  },
  {
    path: '/redirect',
    component: Layout,
    name: 'Redirect',
    children: [
      {
        path: '/redirect/:path(.*)',
        name: 'Redirect',
        component: () => import('@/views/Redirect/Redirect.vue'),
        meta: {}
      }
    ],
    meta: {
      hidden: true,
      noTagsView: true
    }
  },
  {
    path: '/login',
    component: () => import('@/views/Login/Login.vue'),
    name: 'Login',
    meta: {
      hidden: true,
      title: t('router.login'),
      noTagsView: true
    }
  },
  {
    path: '/404',
    component: () => import('@/views/Error/404.vue'),
    name: 'NoFind',
    meta: {
      hidden: true,
      title: '404',
      noTagsView: true
    }
  }
]

export const asyncRouterMap: AppRouteRecordRaw[] = [
  // {
  //   path: '/dashboard',
  //   component: Layout,
  //   redirect: '/dashboard/analysis',
  //   name: 'Dashboard',
  //   meta: {
  //     title: t('router.dashboard'),
  //     icon: 'ant-design:dashboard-filled',
  //     alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: 'analysis',
  //       component: () => import('@/views/Dashboard/Analysis.vue'),
  //       name: 'Analysis',
  //       meta: {
  //         title: t('router.analysis'),
  //         noCache: true,
  //         affix: true
  //       }
  //     }
  //   ]
  // },
  {
    path: '/dashboard',
    component: Layout,
    redirect: '/dashboard/analysis',
    meta: {},
    name: 'Dashboard',
    children: [
      {
        path: 'analysis',
        component: () => import('@/views/Dashboard/Analysis.vue'),
        name: 'Analysis',
        meta: {
          title: '分析页',
          icon: 'ant-design:dashboard-filled'
        }
      }
    ]
  },
  // {
  //   path: '/admin',
  //   component: Layout,
  //   meta: {},
  //   name: 'Admin',
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/Admin/Admin.vue'),
  //       name: 'AdminIndex',
  //       meta: {
  //         title: '管理员',
  //         icon: 'clarity:administrator-line'
  //       }
  //     }
  //   ]
  // },
  {
    path: '/imageReview',
    component: Layout,
    redirect: '/imageReview/index',
    meta: {},
    name: 'ImageReview',
    children: [
      {
        path: 'index',
        component: () => import('@/views/ImageReview/Index.vue'),
        name: 'ImageReviewIndex',
        meta: {
          title: '图片审核',
          icon: 'clarity:tape-drive-solid'
        }
      }
    ]
  },
  {
    path: '/awardInformation',
    component: Layout,
    meta: {},
    name: 'AwardInformation',
    children: [
      {
        path: 'index',
        component: () => import('@/views/AwardInformation/Index.vue'),
        name: 'AwardInformationIndex',
        meta: {
          title: '领奖信息',
          icon: 'clarity:block-line'
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  strict: true,
  routes: constantRouterMap as RouteRecordRaw[],
  scrollBehavior: () => ({ left: 0, top: 0 })
})

export const resetRouter = (): void => {
  router.getRoutes().forEach((route) => {
    const { name } = route
    if (name && !NO_RESET_WHITE_LIST.includes(name as string)) {
      router.hasRoute(name) && router.removeRoute(name)
    }
  })
}

export const setupRouter = (app: App<Element>) => {
  app.use(router)
}

export default router
